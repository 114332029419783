"use client"

import React from "react"
import { deleteAllCurrentLineItemsAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/components/alert-dialog"
import { UbBasketDeleteAllActionStoryblok } from "@/components/storyblok/component-types"

interface BasketDeleteAllActionProps {
  blok: UbBasketDeleteAllActionStoryblok
}

export default function BasketDeleteAllAction(props: BasketDeleteAllActionProps) {
  const t = useTranslations("storefront")

  const { basketData, basketLineItems, setBasketData, setIsLoading } = useUbStorefrontState()

  function deleteAllCurrentLineItems() {
    setIsLoading(true)

    deleteAllCurrentLineItemsAction().then((result) => {
      if (result) {
        setBasketData(result)
      }
      setIsLoading(false)
    })
  }

  return (
    <>
      {basketData &&
        (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
        basketLineItems &&
        basketLineItems.length > 0 && (
          <AlertDialog>
            <AlertDialogTrigger>
              <p className={"underline cursor-pointer text-xs text-gray-600 hover:text-black"}>{props.blok.label}</p>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{props.blok.alert_title}</AlertDialogTitle>
                <AlertDialogDescription>{props.blok.alert_message}</AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
                <AlertDialogAction onClick={deleteAllCurrentLineItems}>{t("common.confirm")}</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
    </>
  )
}

import React from "react"

import { BasketLineItemData } from "@/lib/storefront/basket/basket"
import { BasketLineItemCard } from "@/components/basket/overview/BasketLineItemCard"

export function BasketLineItemList(props: { isB2B: boolean; basketLineItems: BasketLineItemData[]; sbLocale: string }) {
  return (
    <div>
      {props.basketLineItems && (
        <div className={"flex flex-col gap-0"}>
          {props.basketLineItems.map((lineItem, index) => (
            <div className={"w-full border-b border-t-0 border-gray-300 first:border-t"} key={lineItem.product.id}>
              <BasketLineItemCard isB2B={props.isB2B} lineItem={lineItem} sbLocale={props.sbLocale} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

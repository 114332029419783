"use client"

import React, { PropsWithChildren, Suspense } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { Skeleton } from "@/components/ui/components"
import BasketRecentlyViewedProducts from "@/components/basket/BasketRecentlyViewedProducts"
import { BasketLineItemList } from "@/components/basket/overview/BasketLineItemList"
import { UbBasketLineItemsStoryblok } from "@/components/storyblok/component-types"

interface BasketLineItemsProps extends PropsWithChildren {
  basketContext: BasketContext
  blok: UbBasketLineItemsStoryblok
}

export default function BasketLineItemsClient(props: BasketLineItemsProps) {
  const isB2B = props.basketContext.isB2B

  const { basketData, basketLineItems } = useUbStorefrontState()

  return (
    <div className={"flex w-full flex-col gap-2"}>
      <Suspense>
        <>
          {basketData &&
          (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
          basketLineItems &&
          basketLineItems.length > 0 ? (
            <>
              <div className={"grid w-full grid-cols-1 gap-y-2"}>
                <div className={"col-span-2"}>
                  <div className={"flex flex-col gap-2"}>
                    <BasketLineItemList
                      isB2B={isB2B}
                      basketLineItems={basketLineItems}
                      sbLocale={props.basketContext.sbLocale}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {!basketData && (
                <div className="flex flex-col gap-4">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
              )}

              <div>{basketData && (basketData?.data?.totalProductQuantity ?? 0) < 1 && <p>Warenkorb ist leer.</p>}</div>
            </>
          )}
        </>
      </Suspense>

      <div className={"w-full"}>
        <BasketRecentlyViewedProducts />
      </div>
    </div>
  )
}

import React from "react"
import { useTranslations } from "next-intl"

import { BasketLineItemData } from "@/lib/storefront/basket/basket"
import { cn } from "@/components/ui/helpers/utils"

export function BasketLineItemPriceInfo(props: { isB2B: boolean; lineItem: BasketLineItemData | undefined }) {
  const t = useTranslations("storefront")
  const lineItem = props.lineItem

  const discountType = lineItem?.discountType ?? ""
  const isDiscounted = lineItem?.isDiscounted

  return (
    <div
      className={cn("flex h-full flex-col justify-between gap-1", {
        "justify-end": !props.isB2B,
      })}
    >
      {/*  <pre>{JSON.stringify(lineItem)}</pre>*/}

      {props.isB2B && (
        <div className={"flex flex-col justify-start gap-1"}>
          <div className={"flex flex-col justify-start items-end"}>
            <div className={"flex flex-row justify-end gap-1 text-xs"}>
              <p className={"min-w-24 text-right font-bold"}>{t("basket.add_dialog.normal")}:</p>
              <p
                className={cn("min-w-24 text-right decoration-primary", {
                  "line-through": isDiscounted,
                })}
              >
                {lineItem?.totalListPriceFormatted}
              </p>
            </div>
            <div
              className={cn("hidden flex-row justify-end gap-1 text-xs", {
                flex: isDiscounted,
              })}
            >
              {(() => {
                switch (discountType) {
                  /*Preisliste*/

                  case "PRICELIST":
                    return (
                      <p className={"min-w-24 text-right font-bold text-green-500"}>
                        {t("basket.add_dialog.discount", {
                          discountPercentFormatted: lineItem?.discountPercentFormatted,
                        })}
                        :
                      </p>
                    )
                  /*Rahmenrabatt*/
                  case "GENERAL":
                    return (
                      <p className={"min-w-24 text-right font-bold text-green-500"}>
                        {t("basket.add_dialog.discount_general", {
                          discountPercentFormatted: lineItem?.discountPercentFormatted,
                        })}
                        :
                      </p>
                    )
                  /*Gutschein*/
                  case "VOUCHER":
                    return (
                      <p className={"min-w-24 text-right font-bold text-green-500"}>
                        {t("basket.add_dialog.discount_voucher", {
                          discountPercentFormatted: lineItem?.discountPercentFormatted,
                        })}
                        :
                      </p>
                    )
                  /*Sonderrabatt Kategorie*/
                  default:
                    return (
                      <p className={"min-w-24 text-right font-bold text-green-500"}>
                        {t("basket.add_dialog.discount_category", {
                          discountPercentFormatted: lineItem?.discountPercentFormatted,
                        })}
                        :
                      </p>
                    )
                }
              })()}

              <p className={"min-w-24 text-right"}>{lineItem?.discountAmountFormatted}</p>
            </div>

            <div
              className={cn("hidden flex-row justify-end gap-1 text-xs", {
                flex: lineItem?.ecoTaxFormatted && lineItem.ecoTaxFormatted !== "",
              })}
            >
              <p className={"min-w-24 text-right"}>{t("basket.add_dialog.eco_tax")}:</p>
              <p className={"min-w-24 text-right"}>{lineItem?.ecoTaxFormatted}</p>
            </div>
            <div className={"flex flex-row justify-between gap-1 text-xs"}>
              <p className={"min-w-24 text-right"}>{t("basket.add_dialog.single")}:</p>
              <p className={"min-w-24 text-right"}>{lineItem?.salePriceFormatted}</p>
            </div>
          </div>
        </div>
      )}

      <div>
        {!props.isB2B && (
          <div className={"flex flex-row justify-end gap-1"}>
            <p className={"text-nowrap text-xs font-bold text-primary"}>{t("basket.add_dialog.special_offer")}</p>
          </div>
        )}
        <div className={"flex flex-row justify-end items-center gap-1 text-sm"}>
          <p className={"min-w-24 text-right"}>{t("basket.add_dialog.sum")}:</p>
          <p className={"text-lg min-w-24 text-right font-bold text-primary"}>{lineItem?.totalSalePriceFormatted}</p>
        </div>
      </div>
    </div>
  )
}

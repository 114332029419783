import { BasketLineItemROV1 } from "@ub/basket-client"

import { BasketData, DiscountType } from "@/lib/intershop/interfaces/basket/basket"
import { BasketLineItemData, BasketPromotionCode, BasketTotalData, DiscountData } from "@/lib/storefront/basket/basket"

export function findLineItemForSku(basket: BasketData, sku?: string): BasketLineItemROV1 | null {
  if (basket.data?.lineItems && sku) {
    for (let lineItemId of basket.data?.lineItems) {
      if (basket.included?.lineItems) {
        const lineItem = basket.included?.lineItems[lineItemId]
        if (lineItem?.product === sku) {
          return lineItem
        }
      }
    }
  }
  return null
}

function extractDISCOUNT_TYPE_SPECIAL_VOUCHER(
  discountAttributeValue: string,
  existingLineItem: BasketLineItemROV1,
  totalListPrice: number
): DiscountData {
  const discountType: DiscountType = "VOUCHER"
  let discountAmount: number = 0
  let discountPercent: number = 0

  const discountAttributeValueArray: string[] = discountAttributeValue.split(";")
  if (discountAttributeValueArray.length > 1) {
    discountPercent = Number.parseFloat(discountAttributeValueArray[1])
  }

  let appliedPromotionIDsValue: string | undefined
  let discountValue: string | undefined

  appliedPromotionIDsValue = existingLineItem["attributes"]?.find(
    (attributeValue) => attributeValue.name === "appliedPromotionIDs"
  )?.value as string
  if (appliedPromotionIDsValue) {
    discountValue = existingLineItem["attributes"]?.find(
      (attributeValue) => attributeValue.name === appliedPromotionIDsValue + "_discountValue"
    )?.value as string
  }
  if (discountValue) {
    const discountValueArray: string[] = discountValue.split(";")
    if (discountValueArray.length > 1) {
      discountAmount = Number.parseFloat(discountValueArray[1])
    }
  }
  return { discountType, discountPercent, discountAmount }
}

function extractDISCOUNT_TYPE_SPECIAL_GENERAL(
  discountAttributeValue: string,
  existingLineItem: BasketLineItemROV1,
  totalListPrice: number
): DiscountData {
  const discountType: DiscountType = "GENERAL"
  let discountAmount: number
  let discountPercent: number = 0

  const discountAttributeValueArray: string[] = discountAttributeValue.split(";")
  if (discountAttributeValueArray.length > 1) {
    discountPercent = Number.parseFloat(discountAttributeValueArray[1])
  }

  discountAmount = (totalListPrice * discountPercent) / 100

  return { discountType, discountPercent, discountAmount }
}

export function determineDiscountDataFromLineItem(lineItem: BasketLineItemROV1 | null, totalListPrice: number) {
  let discountAttributeValue: string | undefined
  let discountType: DiscountType = "SPECIAL"
  let discountAmount: number = 0.0
  let discountPercent: number = 0.0
  if (lineItem) {
    discountAttributeValue = lineItem["attributes"]?.find((attributeValue) => attributeValue.name === "UBPLIRebateRate")
      ?.value as string

    if (discountAttributeValue) {
      if (discountAttributeValue.startsWith("DISCOUNT_TYPE_CUSTOMER_GENERAL")) {
        discountType = "GENERAL"
        const __ret = extractDISCOUNT_TYPE_SPECIAL_GENERAL(discountAttributeValue, lineItem, totalListPrice)
        discountType = __ret.discountType
        discountPercent = __ret.discountPercent
        discountAmount = __ret.discountAmount
      } else if (discountAttributeValue.startsWith("DISCOUNT_TYPE_SPECIAL_VOUCHER")) {
        const __ret = extractDISCOUNT_TYPE_SPECIAL_VOUCHER(discountAttributeValue, lineItem, totalListPrice)
        discountType = __ret.discountType
        discountPercent = __ret.discountPercent
        discountAmount = __ret.discountAmount
      } else if (discountAttributeValue.startsWith("DISCOUNT_TYPE_CATEGORY_GENERAL")) {
        const __ret = extractDISCOUNT_TYPE_SPECIAL_GENERAL(discountAttributeValue, lineItem, totalListPrice)
        discountType = __ret.discountType
        discountPercent = __ret.discountPercent
        discountAmount = __ret.discountAmount
      } else if (discountAttributeValue.startsWith("DISCOUNT_TYPE_SPECIAL_PRICELIST")) {
        const __ret = extractDISCOUNT_TYPE_SPECIAL_GENERAL(discountAttributeValue, lineItem, totalListPrice)
        discountType = "PRICELIST"
        discountPercent = __ret.discountPercent
        discountAmount = __ret.discountAmount
      }
    }
  }

  const discountData: DiscountData = {
    discountType: discountType,
    discountAmount: discountAmount,
    discountPercent: discountPercent,
  }

  return discountData
}

export function determineBasketTotalData(
  isB2B: boolean,
  basketData?: BasketData,
  basketLineItems?: BasketLineItemData[],
  basketPromotionCodes?: BasketPromotionCode[]
): BasketTotalData {
  //const itemTotalNet = basketData?.data?.totals?.itemTotal?.net?.value ?? 0
  //const itemTotalGross = basketData?.data?.totals?.itemTotal?.gross?.value ?? 0
  const grandTotalNet = basketData?.data?.totals?.grandTotal?.net?.value ?? 0
  const grandTotalGross = basketData?.data?.totals?.grandTotal?.gross?.value ?? 0
  const totalTax = basketData?.data?.totals?.grandTotal?.tax?.value ?? 0
  const valueDiscountsTotalNet = basketData?.data?.totals?.valueDiscountsTotal?.net?.value ?? 0
  const valueDiscountsTotalGross = basketData?.data?.totals?.valueDiscountsTotal?.gross?.value ?? 0
  const shippingTotalNet = basketData?.data?.totals?.shippingTotal?.net?.value ?? 0

  const currency: string = basketData?.data?.totals?.grandTotal?.net?.currency ?? "EUR"

  const taxFactor = Math.round((totalTax / grandTotalNet + 1) * 100) / 100

  let itemTotalNet = 0
  let itemTotalGross = 0
  let totalSavingsNet = 0
  let totalSavingsGeneralNet = 0.0
  let totalSavingsSpecialNet: number = 0.0
  let paymentCostAmountGross = 0.0

  if (basketLineItems) {
    basketLineItems.forEach(function (singleLineItem) {
      totalSavingsNet += singleLineItem.discountAmount
      if (singleLineItem.discountType === "GENERAL") {
        totalSavingsGeneralNet += singleLineItem.discountAmount
      }
      if (singleLineItem.discountType === "SPECIAL") {
        totalSavingsSpecialNet += singleLineItem.discountAmount
      }
      itemTotalNet += singleLineItem.totalListPrice ?? 0
      itemTotalGross += (singleLineItem.totalListPrice ?? 0) * taxFactor
    })
  }

  if (!isB2B) {
    paymentCostAmountGross = basketData?.data?.totals?.paymentCostsTotal?.gross?.value ?? 0
  }

  let promotionCode: string | undefined
  let promotionAmountNet: number = 0
  let promotionAmountGross: number = 0
  if (basketPromotionCodes && basketPromotionCodes.length > 0) {
    promotionCode = basketPromotionCodes[0].code
    promotionAmountNet = -1 * valueDiscountsTotalNet
    promotionAmountGross = -1 * valueDiscountsTotalGross
  }

  return {
    paymentCostAmountGross: paymentCostAmountGross,
    taxFactor: taxFactor,
    totalProductQuantity: basketData?.data?.totalProductQuantity ?? 0,
    itemTotalNet: itemTotalNet,
    itemTotalGross: itemTotalGross,
    grandTotalNet: grandTotalNet,
    grandTotalGross: grandTotalGross,
    shippingTotalNet: shippingTotalNet,
    ecoTaxTotal: basketData?.ecoTaxTotal ?? 0,
    totalSavingsNet: totalSavingsNet + promotionAmountNet,
    totalSavingsGross: itemTotalGross - grandTotalGross,
    totalSavingsGeneralNet: totalSavingsGeneralNet,
    totalSavingsSpecialNet: totalSavingsSpecialNet,
    promotionAmountNet: promotionAmountNet,
    promotionAmountGross: promotionAmountGross,
    totalTax: totalTax,
    promotionCode: promotionCode,
    currency: currency,
  }
}

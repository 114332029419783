import { sendGTMEvent } from "@next/third-parties/google"
import { BasketROV1 } from "@ub/basket-client"
import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"

import { CardPrices, determineCardPrices } from "@/lib/price-utils"
import { BasketLineItemData } from "@/lib/storefront/basket/basket"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { calculateCategoryPathString, determineEffectiveProductId } from "@/lib/storefront/product/productHelper"

export function trackAddToBasket(
  isoLocale: string,
  isB2B: boolean,
  product: ReducedProductData | undefined,
  prices: ProductPriceContainerCtnrROV1 | undefined,
  quantity: number
) {
  if (product && prices && quantity) {
    const cardPrices: CardPrices = determineCardPrices(isoLocale, isB2B, prices, undefined, quantity, product.id)

    const addToCartData = {
      event: "addToCart",
      ecommerce: {
        currencyCode: product.currency,
        add: {
          products: [
            {
              name: product.title,
              id: determineEffectiveProductId(product.id),
              price: cardPrices.salePrice,
              brand: product.brand,
              category: calculateCategoryPathString(product),
              quantity: "" + quantity,
            },
          ],
        },
      },
    }

    sendGTMEvent(addToCartData)
  }
}

export function trackProductClick(product: ReducedProductData, trackingList: string, position: number) {
  /*

//For reference: an original event json payload from legacy app

{
    "event": "productClick",
    "ecommerce": {
    "click": {
      "actionField": {
        "list": "Category Resultslist",
          "action": "click"
      },
      "products": [
        {
          "category": "Programm GW-Mailand",
          "id": "A254411",
          "list": "Category Resultslist",
          "name": "Elektrisch höhenverstellbarer Schreibtisch GW-MAILAND 7909",
          "position": 1
        }
      ]
    }
  },
    "gtm.uniqueEventId": 244
  }*/

  const trackedProductId = product.id.toLowerCase().startsWith("e") ? product.id.toUpperCase() : "A" + product.id

  const productClickData = {
    event: "productClick",
    ecommerce: {
      click: {
        actionField: {
          list: trackingList,
          action: "click",
        },
        products: [
          {
            category: product.category ?? "",
            id: trackedProductId,
            list: trackingList,
            name: product.title,
            position: position,
          },
        ],
      },
    },
  }

  sendGTMEvent(productClickData)
}

export function trackBasketView(basket: BasketROV1, isB2BCustomer: boolean, basketLineItems: BasketLineItemData[]) {
  const basketViewData = {
    event: "cart_view",
    ecommerce: {
      currencyCode: basket.purchaseCurrency,
      value: isB2BCustomer ? (basket?.totals?.grandTotal?.net ?? 0) : (basket?.totals?.grandTotal?.gross ?? 0),
      cart_view: {
        products: [] as any[],
      },
    },
  }

  for (const basketLineItem of basketLineItems) {
    const productViewData: any = {
      id: determineEffectiveProductId(basketLineItem.product.id),
      price: "152.1",
      brand: basketLineItem.product.brand,
      category: calculateCategoryPathString(basketLineItem.product),
      quantity: basketLineItem.quantity,
    }

    basketViewData.ecommerce.cart_view.products.push(productViewData)
  }

  sendGTMEvent(basketViewData)
}
